// ==============================================
// CATEGORY SEO BLOC
// ==============================================

[id="category-seo-block"] {
    margin-top: 3rem;

    h1 {
        @extend .title-1;
    }

    h2 {
        @extend .title-2;
    }

    h3 {
        @extend .title-3;
    }

    h4 {
        @extend .title-4;
    }

    > .seo-text-content {
        margin-bottom: 1rem;
        line-height: 1.4;

        > * + * {
            margin-top: .83em;
        }

        a {
            color: $c-pink !important;
            text-decoration: underline;
        }

        strong {
            font-family: $f-avenir-black;
        }

        em {
            font-style: italic;
        }

        ul,
        ol {
            padding-left: 4rem;
            margin: 1.4rem 0;
            list-style-position: inside;
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        table {
            tr {
                td {
                    vertical-align: middle;
                    padding: 1rem 1.5rem;
                    font-size: 1.4rem;
                    line-height: 1.5;
                }
            }
        }
    }

    > .seo-text-button {
        text-decoration: underline;
        transition: color .3s;

        &:hover,
        &:focus,
        &:active {
            color: $c-pink;
        }

        &::after {
            display: inline-block;
            vertical-align: middle;
            margin-left: .5rem;
            transform: rotate(90deg);
            @extend %icomoon;
            content: "\e930";
        }
    }
}
